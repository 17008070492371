import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/habanero-w9/habanero-w9/apps/next/src/app/_components/Global/GoogleTagManager/google-tag-manager.client.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/apps/next/src/app/_components/Global/LiveVisualEditing/live-visual-editing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/habanero-w9/habanero-w9/apps/next/src/app/_components/Global/SanityImage/SanityImage.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/apps/next/src/app/_components/Global/SkipLink/skip-link.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/apps/next/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./_assets/fonts/ProximaNova-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./_assets/fonts/ProximaNova-RegularIt.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./_assets/fonts/ProximaNova-Semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./_assets/fonts/ProximaNova-SemiboldIt.woff2\",\"weight\":\"600\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-family-primary\",\"fallback\":[\"system-ui\",\"sans-serif\"]}],\"variableName\":\"proximaNova\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/habanero-w9/habanero-w9/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./_assets/fonts/literata-v14-latin-600.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./_assets/fonts/literata-v14-latin-600italic.woff2\",\"weight\":\"600\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-family-secondary\",\"fallback\":[\"serif\"]}],\"variableName\":\"literata\"}");
