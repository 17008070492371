'use client';

// import { useLiveMode } from '@sanity/react-loader';
import { VisualEditing, VisualEditingProps } from 'next-sanity';
import { useEffect } from 'react';

// import { client } from '@/_lib/client';

// Always enable stega in Live Mode
// const stegaClient = client.withConfig({ stega: true });

type LiveVisualEditingProps = VisualEditingProps;

export default function LiveVisualEditing(props: LiveVisualEditingProps) {
  // useLiveMode({ client: stegaClient });

  useEffect(() => {
    // If not an iframe or a Vercel Preview deployment, turn off Draft Mode
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview' && window === parent) {
      location.href = '/api/draft-mode/disable';
    }
  }, []);

  return <VisualEditing {...props} />;
}
